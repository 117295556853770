import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Footer, NavBar, CartBtnMobile } from '@cmp/site'
import { AnnouncementBanner } from '@cmp/sections'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Seo } from '@kogk/common'
import { useLocation } from '@reach/router'
import { useGlobalData } from '@src/data/global-data'
import BaseLayout from './BaseLayout'
import styles from './MainLayout.module.scss'
import Modal from '@cmp/site/Modal'
import { FooterVisibilityContext } from '@root/src/context/FooterVisibilityContext'
import { CartVisibility } from '@root/src/context/CartVisibility'
import { useCartState } from '@src/context/Cart'
import cn from 'classnames'

const MainLayout = ({ children, isSnyrtivara, preloadedImages = [] }) => {
  const { t } = useLanguage()
  const { showFooter } = useContext(FooterVisibilityContext)
  const { cartVisible } = useContext(CartVisibility)
  const { cartSize } = useCartState()
  const { announcementBannerData } = useGlobalData()

  const [hideBanner, setHideBanner] = useState(true)

  const closeBannerHandler = () => {
    setHideBanner(true)
    localStorage.setItem('userBannerVisibility', true)
  }

  useEffect(() => {
    if (announcementBannerData.show === true) {
      if (
        typeof window === 'object' &&
        window.localStorage?.getItem('userBannerVisibility')
      ) {
        setHideBanner(localStorage.getItem('userBannerVisibility'))
      } else {
        setHideBanner(false)
      }
    }
  }, [])

  return (
    <BaseLayout>
      <Meta />
      <Helmet>
        {preloadedImages.length &&
          preloadedImages.map((image, index) => {
            return (
              <link
                key={index}
                rel='preload'
                as='image'
                href={image}
                type='image/jpeg'
                data-react-helmet='true'
              />
            )
          })}
      </Helmet>

      <a href='#main-content' className={styles.skipToMain}>
        {t('jump to content')}
      </a>

      <Modal />
      <NavBar isSnyrtivara={isSnyrtivara} />

      <main id='main-content' className={styles.main}>
        {!hideBanner && (
          <AnnouncementBanner
            onClick={() => closeBannerHandler()}
            text={announcementBannerData.text}
            type={announcementBannerData.type}
          />
        )}
        {children}
      </main>
      {showFooter && (
        <Footer className={styles.footer} isSnyrtivara={isSnyrtivara} />
      )}
    </BaseLayout>
  )
}

const Meta = () => {
  const { t, language } = useLanguage()
  const { pathname } = useLocation()
  const { siteUrl } = useGlobalData()

  const currentUrl = `${siteUrl}${pathname}`

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link data-react-helmet='true' rel='icon' href='/favicon.png' />
        <meta name='msapplication-TileColor' content={styles.themeColor} />
        <meta name='theme-color' content={styles.themeColor} />
        <html lang={language} />
      </Helmet>

      <Seo
        title={t`site title`}
        og={{
          type: t`meta|og:type`,
          url: currentUrl,
          title: t`meta|og:title`
        }}
        meta={{
          description: t`meta|description`,
          keywords: t`meta|keywords`
        }}
      />
    </>
  )
}

export default MainLayout
