import React from 'react'
import { formatPriceString } from '@src/utils'
import ProgressBar from '@cmp/site/ProgressBar'
import { Button, PickupDisclaimer } from '@cmp/site'
import { pluralize, formatDate } from '@root/src/utils/index'
import { ValidationMessage } from '@root/src/components/site/index'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

const quantityAsText = item => {
  return `${item.quantityPerPackage} ${(
    item.measurement ?? ''
  ).toLowerCase()} í pakka`
}

const getErrorMessage = (t, item, error) => {
  if (error) {
    return error.message
  }
  if (item.nextWithdrawDate) {
    const date = formatDate(new Date(item.nextWithdrawDate))
    return t('lyfjagatt|next-withdraw-limit').replace('{date}', date)
  }
  if (item.recentlyBought) {
    return t('lyfjagatt|recently-bought-prescription')
  }
}

const pl = (n, prefix, plural, singular) =>
  `${n} ${prefix}${pluralize(n, plural, singular)}`

const Overview = ({
  content: { item },
  setVisiblePart,
  description,
  quantity,
  onChangeQuantity,
  inventoryCount,
  price,
  isPriceLoading,
  addToCart,
  isAddingToCart,
  error
}) => {
  const { t } = useLanguage()
  const outOfStock = quantity > inventoryCount
  const disabled = outOfStock || item.nextWithdrawDate != null
  const loading = isPriceLoading || isAddingToCart
  const errorMessage = getErrorMessage(t, item, error)

  return (
    <>
      <div className='modal-content__content__headline'>
        <ProgressBar
          size={130}
          strokeWidth={4}
          progress={item.progress}
          icon='clipboard'
          rotate
        />
        <h3 className='mb-0 mt-3'>{description}</h3>
        <p>
          {item.type === 'LYFSEDILL'
            ? pl(item.withdrawsRemaining, 'afgreiðsl', 'a', 'ur')
            : pl(item.maxQuantity, 'pakk', 'i', 'ar')}{' '}
          eftir
        </p>
      </div>
      <div className='modal-content__border-row d-flex justify-content-between align-items-center py-2 mb-2'>
        <div>
          <p className='hdln--4 mb-0'>Magn</p>
          <p className='parag--small-fixed-size opacity--08 mb-0'>
            {item.type === 'LYFSEDILL' &&
              `${pl(item.pkgPerWithdrawal, 'pakk', 'i', 'ar')} - `}
            {quantityAsText(item)}
          </p>
        </div>
        {item.type === 'LYFJAAVISUN' && (
          <div className='d-flex align-items-center'>
            <Button
              icon='minus'
              circle
              type='circle'
              onClick={() => {
                quantity > 1 && onChangeQuantity(quantity - 1)
              }}
            />
            <span className='parag--medium px-2'>{quantity}</span>
            <Button
              icon='plus'
              type='circle'
              onClick={() => {
                quantity < item.maxQuantity && onChangeQuantity(quantity + 1)
              }}
            />
          </div>
        )}
      </div>
      <div className='modal-content__footer pt-1'>
        {errorMessage && (
          <div className='pb-2'>
            <ValidationMessage message={errorMessage} />
          </div>
        )}
        <div className='modal-content__footer__btn-wrap mt-1'>
          <Button
            onClick={() => setVisiblePart('Synonyms')}
            className='mr-2'
            type='ghost'
            label='Sjá samheitalyf'
          />
          <Button
            type='primary'
            label={
              !loading &&
              (outOfStock ? 'Uppselt' : formatPriceString(price, true))
            }
            disabled={disabled}
            icon={loading ? 'loading' : 'cart'}
            onClick={() => !disabled && !loading && addToCart()}
          />
        </div>
        <div className='modal-content__footer__disclaimer d-flex align-items-center mt-3'>
          <PickupDisclaimer />
        </div>
      </div>
    </>
  )
}

export default Overview
