export const parseCardExpiry = s => {
  const match = s.match(/^(?<month>\d\d)[ \/]*(?:20)?(?<year>\d\d)$/)
  return match && match.groups
}

export const validateCardExpiry = s => {
  const match = parseCardExpiry(s)
  if (!match) {
    return 'Gildistími er ekki réttur'
  }

  const { year, month } = match
  const cardDate = new Date(2000 + Number(year), Number(month) - 1)

  const now = new Date()
  const minDate = new Date(now.getFullYear(), now.getMonth())

  if (cardDate.getTime() < minDate.getTime()) {
    return 'Gildistími er útrunninn'
  }
}
