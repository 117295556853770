import React from 'react'
import cn from 'classnames'
import luhn from 'luhn'
import { ValidationMessage } from '@cmp/site'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Container } from '@kogk/common'
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { validateCardExpiry } from '@root/src/utils/form'

export default ({ className, register, errors }) => {
  return (
    <Container className='p-0'>
      <Row className='justify-content-center'>
        <Col className='w-100'>
          <div className={cn('form-input form-input__card-info', className)}>
            <fieldset>
              <label className='card-input'>
                Kortanúmer
                <div className='position-relative'>
                  <FontAwesomeIcon icon={faCreditCard} />
                  <input
                    ref={register({
                      required: 'Kortanúmer má ekki vera autt',
                      validate: s => {
                        s = s.replace(/[ -]/g, '')
                        if (s.length !== 16 || !luhn.validate(s)) {
                          return 'Kortanúmer er ekki gilt'
                        }
                      }
                    })}
                    className={cn({ error: errors.cardNumber })}
                    name='cardNumber'
                    type='text'
                    inputMode='numeric'
                  />
                </div>
                {errors.cardNumber && (
                  <ValidationMessage message={errors.cardNumber.message} />
                )}
              </label>

              <label>
                Nafn korthafa
                <input
                  ref={register({
                    required: 'Nafn korthafa má ekki vera autt'
                  })}
                  className={cn({ error: errors.cardName })}
                  name='cardName'
                  type='text'
                />
                {errors.cardName && (
                  <ValidationMessage message={errors.cardName.message} />
                )}
              </label>
              <div className='d-flex'>
                <label className='mr-1'>
                  Rennur út
                  <input
                    ref={register({
                      required: 'Gildistími má ekki vera tómur',
                      validate: validateCardExpiry
                    })}
                    className={cn({ error: errors.cardExpiry })}
                    name='cardExpiry'
                    type='text'
                    inputMode='numeric'
                    placeholder='MM / ÁÁ'
                  />
                  {errors.cardExpiry && (
                    <ValidationMessage message={errors.cardExpiry.message} />
                  )}
                </label>

                <label className='ml-1'>
                  CVV
                  <input
                    ref={register({
                      required: 'Öryggisnúmer má ekki vera autt',
                      pattern: {
                        value: /^\d{3,4}$/,
                        message: 'Öryggisnúmer verður að vera 3-4 tölustafir'
                      }
                    })}
                    className={cn({ error: errors.cvc })}
                    pattern='\d*'
                    maxLength='4'
                    name='cvc'
                    type='tel'
                    inputMode='numeric'
                  />
                  {errors.cvc && (
                    <ValidationMessage message={errors.cvc.message} />
                  )}
                </label>
              </div>
            </fieldset>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
