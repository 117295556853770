import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faPills } from '@fortawesome/pro-solid-svg-icons'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import styles from './ProductRow.module.scss'
import cn from 'classnames'
import { useCartActions } from '@src/context/Cart'
import productFallbackImg from '@src/assets/logos/LH-logo-trans.png'
import { Link } from '@kogk/gatsby-theme-base'
import { formatPriceString } from '@src/utils'

export default ({
  image,
  description,
  price,
  quantity,
  maxQuantity,
  variants,
  quickView,
  checkout,
  productType,
  id,
  productLink = '/',
  discount,
  fullPrice
}) => {
  const rowClasses = cn(
    styles.row,
    { [styles.quickView]: quickView },
    { [styles.checkout]: checkout }
  )

  const FallbackImage = {
    prescription: (
      <div className={styles.fallBack}>
        <FontAwesomeIcon icon={faPills} />
      </div>
    ),
    product: (
      <div className={styles.fallBack}>
        <div style={{ backgroundImage: `url(${productFallbackImg})` }} />
      </div>
    )
  }

  const { changeQuantity, loading } = useCartActions()
  return (
    <div className={rowClasses}>
      <div className='d-flex justify-content-between'>
        <Link to={productLink}>
          {Boolean(image) ? <img src={image} className={styles.image} /> : FallbackImage[productType]}
        </Link>
        <div className={styles.prodInfo}>
          <div className='d-flex'>
            {discount > 0 && (
              <p className={cn(styles.price, 'price-tag--striked')}>
                {formatPriceString(fullPrice, true)}
              </p>
            )}
            <p
              className={cn(styles.price, {
                'price-tag--discount': discount > 0
              })}
            >
              {formatPriceString(price, true)}
            </p>
          </div>
          <p className={styles.description}>{description}</p>

          <div className='d-flex align-items-center'>
            <select
              className='parag parag--x-small-fixed-size select select__product-quantity pl-0'
              name='quantity'
              id='quantity'
              value={quantity}
              onChange={e => changeQuantity(id, parseInt(e.target.value, 10))}
            >
              {Array(maxQuantity ?? quantity + 20)
                .fill()
                .map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1} stk
                  </option>
                ))}
            </select>
            {variants &&
              variants.map((item, key) => {
                return (
                  <p key={key} className={styles.variants}>
                    {item}
                  </p>
                )
              })}
          </div>
        </div>
      </div>
      <button
        className={cn('no-style d-flex align-items-center pr-0', {
          [styles.deleteBtn]: !loading
        })}
        onClick={() => changeQuantity(id, 0)}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        )}
      </button>
    </div>
  )
}
